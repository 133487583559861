/* eslint-disable quote-props, no-dupe-keys, max-len */

import { toRem, toRems } from '../../../../helpers'

// @TODO: THEME NET
const style = ({ theme }) => {
    const config = {
        sm: {
            imageWrapper: {
                height: toRem(200),
                label: {
                    fontSize: toRem(14),
                    padding: toRems([5, 4])
                }
            },
            title: {
                fontSize: toRem(15),
                lineHeight: 1.2
            },
            priceWrapper: {
                minHeight: toRem(35),
                price: {
                    fontSize: toRem(16),
                    currency: {
                        fontSize: toRem(16)
                    }
                },
                oldPrice: {
                    fontSize: toRem(16)
                },
                priceSm: {
                    fontSize: toRem(11),
                    currency: {
                        fontSize: toRem(11)
                    }
                },
                oldPriceSm: {
                    fontSize: toRem(11)
                }
            },
            description: {
                fontSize: toRem(14),
                minHeight: toRem(24)
            }
        },
        md: {
            imageWrapper: {
                height: toRem(300),
                label: {
                    fontSize: toRem(16),
                    padding: toRems([8, 8, 4, 6])
                }
            },
            title: {
                fontSize: toRem(18),
                lineHeight: 1.2
            },
            priceWrapper: {
                minHeight: toRem(35),
                price: {
                    fontSize: toRem(26),
                    currency: {
                        fontSize: toRem(20)
                    }
                },
                oldPrice: {
                    fontSize: toRem(18)
                },
                priceSm: {
                    fontSize: toRem(18),
                    currency: {
                        fontSize: toRem(12)
                    }
                },
                oldPriceSm: {
                    fontSize: toRem(12)
                }
            },
            description: {
                fontSize: toRem(18),
                minHeight: toRem(25)
            }
        }
    }

    return {
        '&>a.fullHeight': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '.listalicaCard': {
            '&_imageWrapper': {
                '&_label': {
                    letterSpacing: toRem(1),
                    color: theme.colors.text.inverted,
                    backgroundColor: theme.colors.background.accent,
                    fontSize: config.sm.imageWrapper.label.fontSize,
                    padding: config.sm.imageWrapper.label.padding,
                    position: 'absolute',
                    zIndex: theme.zIndex.over,
                    right: 0,
                    top: toRem(15),
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.imageWrapper.label.fontSize,
                        padding: config.md.imageWrapper.label.padding,
                        top: toRem(30)
                    },
                    boxShadow: '-1px 1px 1px 1px rgba(8,8,8,0.2)'
                }
            },
            '&_title': {
                marginTop: toRem(20),
                letterSpacing: '-0.03em',
                fontWeight: 700,
                color: theme.colors.text.default,
                fontSize: config.sm.title.fontSize,
                lineHeight: config.sm.title.lineHeight,
                [theme.breakpoints.up('md')]: {
                    fontSize: config.md.title.fontSize,
                    lineHeight: config.md.title.lineHeight
                }
            },
            '&_priceWrapper': {
                margin: toRems([8, 0]),
                '& > *': {
                    lineHeight: 1
                },
                '&_price': {
                    position: 'relative',
                    letterSpacing: toRem(1),
                    lineHeight: 1,
                    color: theme.colors.text.accent,
                    fontFamily: theme.typography.fontFamilyAlt,
                    fontSize: config.sm.priceWrapper.price.fontSize,
                    fontWeight: theme.typography.fontWeight.black,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.priceWrapper.price.fontSize
                    },
                    '&_sm': {
                        fontSize: config.sm.priceWrapper.priceSm.fontSize,
                        [theme.breakpoints.up('md')]: {
                            fontSize: config.md.priceWrapper.priceSm.fontSize
                        }
                    },
                    '&_currency': {
                        letterSpacing: toRem(0),
                        // lineHeight: 2.5,
                        fontSize: config.sm.priceWrapper.price.currency.fontSize,
                        [theme.breakpoints.up('md')]: {
                            fontSize: config.md.priceWrapper.price.currency.fontSize
                        },
                        '&_sm': {
                            fontSize: config.sm.priceWrapper.priceSm.currency.fontSize,
                            [theme.breakpoints.up('md')]: {
                                fontSize: config.md.priceWrapper.priceSm.currency.fontSize
                            }
                        }
                    }
                },
                '&_oldPrice': {
                    marginLeft: toRem(5),
                    letterSpacing: toRem(1),
                    // lineHeight: 2,
                    color: theme.colors.text.placeholder,
                    textDecoration: 'line-through',
                    fontSize: config.sm.priceWrapper.oldPrice.fontSize,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.priceWrapper.oldPrice.fontSize
                    },
                    '&_sm': {
                        fontSize: config.sm.priceWrapper.oldPriceSm.fontSize,
                        [theme.breakpoints.up('md')]: {
                            fontSize: config.md.priceWrapper.oldPriceSm.fontSize
                        }
                    }
                }
            },
            '&_description': {
                margin: toRems([0, 0, 30, 0]),
                lineHeight: 1.4,
                color: theme.colors.text.placeholder,
                fontWeight: 400,
                fontSize: config.sm.description.fontSize,
                minHeight: config.sm.description.minHeight,
                [theme.breakpoints.up('md')]: {
                    lineHeight: 1.6,
                    fontSize: config.md.description.fontSize,
                    minHeight: config.md.description.minHeight
                },
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '3'
            }
        }
    }
}

export default style
