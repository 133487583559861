import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { articleCardSizeVariants } from '../Article/components'
import { ConditionalWrapper } from '../ConditionalWrapper'
import { Image, imageRatioVariants } from '../Image'
import { Link } from '../Link'
import styles from './SectionTitle.net.style'

const SectionTitleStyled = styled.div(props => ({ ...styles(props) }))

const netSectionTitleVariants = Object.freeze({
    net: 'net',
    danas: 'danas',
    sport: 'sport',
    hot: 'hot',
    magazin: 'magazin',
    webCaffe: 'webCaffe',
    fun: 'fun',
    telesport: 'telesport',
    super1: 'super1',
    tag: 'tag'
})

function SectionTitle({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    className,
    titleTag: SectionTitleTag,
    title,
    titleUrl,
    section,
    sizeVariant,
    styleVariant,
    image,
    borderColor,
    isListing,
    archiveTitle,
    isExternal,
    ...rest
}) {
    if (!title) {
        return null
    }

    return (
        <SectionTitleStyled
            section={section}
            sizeVariant={sizeVariant}
            styleVariant={styleVariant}
            borderColor={borderColor}
            isListing={isListing}
            hasUrl={!!titleUrl}
            image={image}
            id={`section_title_${title ? title?.toLowerCase()?.replace(/ /gim, '_') : ''}`}
            className="sectionTitle_wrapper"
            {...rest}>
            <ConditionalWrapper
                condition={!!titleUrl}
                Wrapper={Link}
                isExternal={isExternal}
                href={titleUrl}
                as={titleUrl}
                title={title}>
                {image && (
                    <div className="sectionTitle_logo" id="section_title_logo">
                        <Image image={image} alt={title} variation={imageRatioVariants.CUSTOM_ORIGINAL} />
                    </div>
                )}
                {!image && title && (
                    <SectionTitleTag
                        className="sectionTitle_title"
                        id={`section_title_${title ? title?.toLowerCase()?.replace(/ /gim, '_') : ''}`}>
                        {section === 'tag' && <span className="tagSymbol">#</span>}
                        {title}
                        {section === 'archive' && <span className="archiveTitle">{archiveTitle}</span>}
                    </SectionTitleTag>
                )}
            </ConditionalWrapper>
        </SectionTitleStyled>
    )
}

const netSectionTitleSizeVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '1x3': '1x3',
    '2x3': '2x3',
    '1x4': '1x4',
    '1x6': '1x6'
})

const netSectionTitleStyleVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

SectionTitle.propTypes = {
    className: PropTypes.string,
    titleTag: PropTypes.string,
    title: PropTypes.string,
    section: PropTypes.string,
    sizeVariant: PropTypes.oneOf([...Object.values(articleCardSizeVariants)]),
    styleVariant: PropTypes.oneOf([...Object.values(netSectionTitleStyleVariants)]),
    titleUrl: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    borderColor: PropTypes.string,
    isListing: PropTypes.bool,
    archiveTitle: PropTypes.string,
    isExternal: PropTypes.bool
}

SectionTitle.defaultProps = {
    className: undefined,
    titleTag: 'h1',
    title: undefined,
    section: undefined,
    sizeVariant: netSectionTitleSizeVariants['1x1'],
    styleVariant: netSectionTitleStyleVariants.ALPHA,
    titleUrl: undefined,
    image: undefined,
    borderColor: undefined,
    isListing: false,
    archiveTitle: undefined,
    isExternal: false
}

export { netSectionTitleVariants, netSectionTitleSizeVariants, netSectionTitleStyleVariants }

export default withErrorBoundary(SectionTitle, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SectionTitle]: ', error, componentStack)
    }
})
