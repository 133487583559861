/* eslint-disable jsx-a11y/alt-text */
import { useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import SwiperCore, { Autoplay, Grid, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { RenderInView } from '@hmn/rtl-web-core/components/RenderInView'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { ArrowLeftIcon } from '../Icon'
import { PaginationButton } from '../Pagination/components'
import { SectionTitle } from '../SectionTitle'
import { ListalicaCard } from './components/Card'
import styles from './ProductGallery.style'

SwiperCore.use([Autoplay, Navigation])
const ListalicaProizvodaStyled = styled.div(props => ({ ...styles(props) }))

function ProductGallery({
    title,
    showLogo,
    logoImage: logo,
    items,
    titleUrl,
    disclaimer,
    className,
    onInViewChange,
    onItemClick
}) {
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const swiperComponent = useRef()

    const slideLeft = () => {
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.slidePrev()
        }
    }

    const slideRight = () => {
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.slideNext()
        }
    }

    const { ref } = useInView({
        initialInView: false,
        threshold: 0.9,
        triggerOnce: true,
        onChange: onInViewChange
    })

    const autoplay = useMemo(() => {
        if ((isDesktop && items?.length > 4) || (!isDesktop && items?.length > 1)) {
            return {
                delay: 4000,
                disableOnInteraction: false
            }
        }

        return undefined
    }, [isDesktop, items?.length])

    if (!items) {
        return null
    }

    return (
        <RenderInView>
            <ListalicaProizvodaStyled className={className} ref={ref}>
                {showLogo && logo && (
                    <SectionTitle titleUrl={titleUrl} isExternal title={title} image={logo} titleTag="h3" />
                )}
                {!showLogo && title && <SectionTitle titleUrl={titleUrl} isExternal title={title} titleTag="h3" />}

                {items?.length > 3 && (
                    <Media greaterThan="sm" scaleDown>
                        <div className="listalicaProizvoda_slider">
                            <PaginationButton
                                icon={ArrowLeftIcon}
                                id="listalica_proizvoda_button_left"
                                className="listalicaProizvoda_button"
                                onClick={slideLeft}
                            />
                            <PaginationButton
                                icon={ArrowLeftIcon}
                                rotate={180}
                                id="listalica_proizvoda_button_right"
                                className="listalicaProizvoda_button"
                                onClick={slideRight}
                            />
                        </div>
                    </Media>
                )}
                <Swiper
                    ref={swiperComponent}
                    className="listalicaProizvoda_swiper"
                    id="listalica_proizvoda_swiper"
                    spaceBetween={isDesktop ? '2%' : 25}
                    grid={isDesktop ? { rows: 1 } : undefined}
                    slidesPerView={isDesktop ? 4 : 'auto'}
                    loop={isDesktop ? items?.length > 3 : items?.length > 1}
                    autoplay={autoplay}
                    modules={isDesktop ? [Grid] : []}
                    navigation={{
                        prevEl: '.swiperNav_prev',
                        nextEl: '.swiperNav_next'
                    }}>
                    {items?.map(item => (
                        <SwiperSlide key={uid(item.id || item)} className="listalicaProizvoda_item">
                            <ListalicaCard
                                onClick={onItemClick}
                                buttonLabel={item.button}
                                buttonHref={item.url}
                                description={item.description}
                                price={item.price}
                                oldPrice={item.priceOld}
                                priceEuro={item.priceEuro}
                                priceOldEuro={item.priceOldEuro}
                                title={item.title}
                                imageId={item.image.id}
                                imageLabel={item.imageLabel}
                                imageView={item.imageView}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                {disclaimer && (
                    <div className="listalicaProizvoda_disclaimer" id="listalica_proizvoda_disclaimer">
                        {disclaimer}
                    </div>
                )}
            </ListalicaProizvodaStyled>
        </RenderInView>
    )
}
ProductGallery.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    showLogo: PropTypes.bool,
    logoImage: PropTypes.shape({
        id: PropTypes.string
    }),
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    titleUrl: PropTypes.string,
    disclaimer: PropTypes.string,
    onInViewChange: PropTypes.func,
    onItemClick: PropTypes.func
}

ProductGallery.defaultProps = {
    className: undefined,
    title: 'Net.hr webshop',
    showLogo: undefined,
    logoImage: undefined,
    items: undefined,
    titleUrl: undefined,
    disclaimer: undefined,
    onInViewChange: undefined,
    onItemClick: undefined
}

export default withErrorBoundary(ProductGallery, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ListalicaProizvoda]: ', error, componentStack)
    }
})
