// import { toRem, toRems } from '@hmn/rtl-web-ui/helpers/theme'

import { getSectionColor, toRem, toRems } from '../../helpers'

const style = ({ theme, sizeVariant, styleVariant, section, borderColor, hasUrl, image }) => {
    const title1x1 = sizeVariant === '1x1'
    const title1x2 = sizeVariant === '1x2'
    const title1x3 = sizeVariant === '1x3'
    const title2x3 = sizeVariant === '2x3'
    const title1x4 = sizeVariant === '1x4'
    const title1x6 = sizeVariant === '1x6'
    const lineColor = borderColor || getSectionColor()

    return {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginBottom: '20px',
        width: '100%',
        ...(image && {
            marginTop: toRem(40)
        }),
        ...(styleVariant === 'alpha' && {
            '&:before': {
                width: '100%',
                content: '""',
                height: 1,
                background: lineColor,
                flex: 1
            },
            '&:after': {
                width: '100%',
                content: '""',
                height: 1,
                background: lineColor,
                flex: 1
            }
        }),
        '& .tagSymbol': {
            color: lineColor
        },
        '& .sectionTitle_title': {
            flexShrink: 1,
            textTransform: 'uppercase',
            textAlign: 'center',
            fontFamily: theme.typography.fontFamilyAlt,
            fontSize: toRem(32),
            fontWeight: theme.typography.fontWeight.black,
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            ...(section === 'archive' && { color: lineColor }),
            transitionProperty: 'color',
            ...(styleVariant === 'beta' && {
                borderBottom: `4px solid ${lineColor}`,
                lineHeight: 1
            }),
            ...(styleVariant === 'alpha' && {
                padding: toRems([0, 10])
            }),
            ...(hasUrl && {
                '&:hover': {
                    color: lineColor
                }
            }),
            [theme.breakpoints.up('md')]: {
                ...(title1x1 && {
                    fontSize: toRem(42)
                }),
                ...(title1x2 && {
                    fontSize: toRem(32)
                }),
                ...(title1x3 && {
                    fontSize: toRem(32)
                }),
                ...(title2x3 && {
                    fontSize: toRem(32)
                }),
                ...(title1x4 && {
                    fontSize: toRem(24)
                }),
                ...(title1x6 && {
                    fontSize: toRem(24)
                })
            }
        },
        '& .sectionTitle_logo': {
            flexShrink: 0,
            position: 'absolute',
            width: toRem(140),
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: theme.colors.background.default,
            paddingBottom: 0,
            paddingRight: 10,
            paddingLeft: 10,
            [theme.breakpoints.up('md')]: {
                width: toRem(200)
            }
        },
        '& .archiveTitle': {
            color: `${theme.colors.text.default}` // !important`
        },

        authorTitle: {
            fontFamily: 'Proxima Nova',
            fontWeight: 800,
            fontSize: '40px',
            lineHeight: '100%',
            color: '#114A79'
        },

        '.author_profession': {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '100%',
            color: '#F15B25'
        },

        '.author_lead': {
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '100%',
            color: '#114A79'
        }
    }
}

export default style
